<template>
  <div>
    <!-- #modal filtros  -->
    <Modal
      modalTitle="Filtros"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="400px"
      :modalFooter="true"
      v-model="dialog"
      @input="input"
    >
      <!-- body  -->
      <template>
        <v-container fluid grid-list-sm pa-3>
          <v-layout align-center wrap>
            <!-- Empresa -->
            <v-flex xs12>
              <InputMultSelect
                v-model="cashFlowStatusZeroFilters.companiesIds"
                textLabel="Empresa"
                valueAttribute="id"
                textAttribute="entity.name"
                :required="false"
                :showAllOptions="true"
                :items="cashFlowFiltersPropListCompanies"
              />
            </v-flex>
            <!-- Campanha -->
            <v-flex xs12>
              <InputMultSelect
                v-model="cashFlowStatusZeroFilters.campaignsIds"
                textLabel="Campanhas"
                valueAttribute="id"
                textAttribute="name"
                unselectedValue="null"
                unselectedText="Todos"
                :items="cashFlowFiltersProp.campaigns"
              />
            </v-flex>
            <!-- Projetos -->
            <v-flex xs12>
              <InputMultSelect
                v-model="cashFlowStatusZeroFilters.projectsIds"
                textLabel="Projetos"
                valueAttribute="id"
                textAttribute="fullName"
                unselectedValue="null"
                unselectedText="Todos"
                :items="cashFlowFiltersPropListProjects"
              />
            </v-flex>
            <!-- Checkout Date -->
            <v-flex xs12 mt-3>
              <InputDateRange
                v-model="checkoutDates"
                textLabel="Período das doações"
                place-holder
                :monthsToShow="2"
              />
            </v-flex>

            <!-- Métodos de Pagamento -->
            <v-flex xs12>
              <InputMultSelect
                v-model="cashFlowStatusZeroFilters.paymentMethodsIds"
                textLabel="Métodos de Pagamento"
                valueAttribute="id"
                textAttribute="name"
                unselectedValue="null"
                unselectedText="Todos"
                :items="paymentMethods"
              />
            </v-flex>
            
            <!-- Status Pagamento -->
            <v-flex xs12>
              <InputSelect
                v-model="cashFlowStatusZeroFilters.paymentStatus"
                textLabel="Status de Pagamento"
                valueAttribute="id"
                textAttribute="name"
                :hasUnselectedOption="false"
                :items="paymentStatus"
              />
            </v-flex>

            <!-- Status de Conciliação -->
            <v-flex xs12 v-show="showConciliationStatus">
              <InputMultSelect
                v-model="cashFlowStatusZeroFilters.conciliationStatus"
                textLabel="Status de Conciliação"
                valueAttribute="id"
                textAttribute="name"
                unselectedValue="null"
                unselectedText="Todos"
                :items="statusConciliation"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </template>

      <!-- action buttons  -->
      <template v-slot:footer>
        <v-container fluid>
          <v-layout>
            <v-flex>
              <v-btn flat round color="white" class="btn-default px-4" large @click="close">Voltar</v-btn>
            </v-flex>
            <v-flex>
              <v-btn
                flat
                round
                color="white"
                class="btn-default px-4"
                large
                @click="clearFilters"
              >Limpar Filtros</v-btn>
            </v-flex>
            <v-flex text-xs-right>
              <v-btn flat color="white" class="btn-primary px-4" large @click="filter">Aplicar</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </Modal>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import { TreeFilter } from "@/scripts/models/tree.model";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum";
import { mapGetters, mapState, mapActions } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";
import CashFlowStatusZeroFilters from "@/scripts/models/cashFlowStatusZeroFilters.model";
import { CashFlowPages, CashFlowPagesNameList, CashFlowPagesForStatusZeroNameList } from "@/scripts/models/enums/cashFlowPages.enum";
import { CashFlowStatusZeroPaymentStatus, CashFlowStatusZeroPaymentStatusList } from "@/scripts/models/enums/cashFlowStatusZeroPaymentStatus.enum";
export default {
  props: ["value", "cashFlowFiltersProp", "pageNumber"],
  components: {
    Modal
  },
  data() {
    return {
      dialog: false,
      showConciliationStatus: true,
      cashFlowFilters: new TreeFilter(),
      cashFlowStatusZeroFilters: new CashFlowStatusZeroFilters(),
      checkoutDates: [],
      pageNumberEnum: "",
      statusConciliation: null,
      paymentStatus: null,
      paymentMethods: [
        { id: PaymentMethod.PayrollDeduction, name: "Débito em Folha" },
        { id: PaymentMethod.Ticket, name: "Boleto" },
        { id: PaymentMethod.CreditCard, name: "Cartão de Crédito" }
      ],
      types: [
        {
          id: ProjectTypes.ProjetoIncentivado,
          name: GetProjectTypeName(ProjectTypes.ProjetoIncentivado)
        },
        {
          id: ProjectTypes.ProjetoSemIncentivoFiscal,
          name: GetProjectTypeName(ProjectTypes.ProjetoSemIncentivoFiscal)
        }
      ],
      cashFlowFiltersPropListCompanies: [],
      cashFlowFiltersPropListProjects: [],
      cashFlowFiltersPropListPaymetMethods: []
    };
  },
  created() {
    this.pageNumberEnum = CashFlowPages;
    this.statusConciliation = CashFlowPagesForStatusZeroNameList;
    this.paymentStatus = CashFlowStatusZeroPaymentStatusList;
  },
  watch: {
    value() {
      this.dialog = this.value;
    },
    checkoutDates: {
      handler: function(after, before) {
        if (after) {
          this.cashFlowStatusZeroFilters.fromCheckoutDate = after[0];
          this.cashFlowStatusZeroFilters.toCheckoutDate = after[1];
        } else {
          this.checkoutDates = [null, null];
        }
      },
      deep: true
    },
    "cashFlowStatusZeroFilters.paymentStatus":{
      handler(after, before){
        if(this.cashFlowStatusZeroFilters.paymentStatus == CashFlowStatusZeroPaymentStatus.Approved){
          this.showConciliationStatus = true;
        }else{
           this.showConciliationStatus = false;
        }
      },
      deep: true
    },
    cashFlowFiltersProp: {
      handler: function(after, before) {
        if (
          !this.cashFlowFiltersProp ||
          !this.cashFlowFiltersProp.companies
        )
          this.cashFlowFiltersPropListCompanies = [];
        this.cashFlowFiltersPropListCompanies = this.cashFlowFiltersProp.companies
          .slice()
          .sort((a, b) =>
            a.entity.name.trim().localeCompare(b.entity.name.trim())
          );
        if (!this.cashFlowFiltersProp || !this.cashFlowFiltersProp.projects)
          this.cashFlowFiltersPropListProjects = [];
        this.cashFlowFiltersPropListProjects = this.cashFlowFiltersProp.projects
          .slice()
          .sort((a, b) => a.fullName.trim().localeCompare(b.fullName.trim()));
        this.cashFlowFiltersPropListPaymetMethods = this.cashFlowFiltersProp.paymen
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.$emit("close");
      this.dialog = false;
    },
    filter() {
      this.$emit("filter", this.cashFlowStatusZeroFilters);
    },
    clearFilters() {
      this.cashFlowStatusZeroFilters = new cashFlowStatusZeroFilters();
      this.filter();
    },
    input() {
      if (!this.dialog) this.$emit("close");
    }
  }
};
</script>
