<template>
  <div>
    <Modal
      :modalTitle="node.father.description"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="640px"
      :modalFooter="true"
      v-model="show"
    >
      <!-- body  -->
      <template>
        <v-container fluid pa-0>
          <v-layout px-4 py-3 row>
            <v-flex>
              <v-layout align-end>
                <v-flex>
                  <h3 class="text-dark f-size-18">
                    <div class="caption">Projeto</div>
                    {{node.description}}
                  </h3>
                </v-flex>
                <v-flex
                  text-xs-right
                  text-dark
                  fw-700
                  f-size-18
                >Total: {{node.totalToConciliate | FormatMoney}}</v-flex>
              </v-layout>
              <InputSearch
                type="text"
                ref="searchInput"
                v-model="searchText"
                :focusOnCreate="true"
                place-holder="Buscar..."
              />
            </v-flex>
          </v-layout>
          <hr class="md my-0" />
          <v-layout pa-0>
            <v-flex list-scroll sm12 style="max-height:calc(100vh - 440px)" ref="listScroll">
              <div v-for="(personDonation,i) in filteredList" :key="i">
                <v-layout align-start py-3 px-4>
                  <v-flex text-gray-dark>
                    <h3 class="fw-400 f-size-18 text-dark">
                      <span class="text-gray-dark mr-2">#{{personDonation.cartId}} -</span>
                      {{personDonation.personName}}
                    </h3>
                    <div v-if="personDonation.registrationId != null">
                      <faIcon type="fal" name="id-card-alt" size="14px" class="mr-3" />
                      {{personDonation.registrationId}}
                    </div>
                    <div>
                      <faIcon type="fal" name="envelope" size="14px" class="mr-3" />
                      {{personDonation.email}}
                    </div>
                    <div>
                      <faIcon type="fal" name="address-card" size="14px" class="mr-3" />
                      {{personDonation.cpf | FormatDocument}}
                    </div>
                  </v-flex>
                  <v-flex
                    text-xs-right
                    f-size-16
                    text-dark
                    fw-700
                    shrink
                  >{{personDonation.donationValue | FormatMoney}}</v-flex>
                </v-layout>
                <hr class="sm my-2" />
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </template>

      <!-- action buttons  -->
      <template v-slot:footer>
        <v-container fluid>
          <v-layout>
            <v-flex>
              <v-btn
                flat
                round
                color="white"
                class="btn-default px-5"
                large
                @click="show = false"
              >Voltar</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </Modal>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import { Node, Tree } from "@/scripts/models/tree.model";
export default {
  components: {
    Modal
  },
  props: {
    node: new Node()
  },
  data() {
    return {
      searchText: "",
      show: false
    };
  },
  computed: {
    nodeComputed() {
      return this.node == null ? new node() : this.node;
    },
    filteredList() {
      if (this.nodeComputed.children.length == 0) return [];
      return this.nodeComputed.children.filter(node => {
        return node.personName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
    }
  },
  methods: {
    open() {
      this.searchText = "";
      this.show = true;
    }
  }
};
</script>