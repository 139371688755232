<template>
  <div>
    <!-- boxapp body -->
    <div class="box-main pa-0">
      <div class="section">
        <v-layout fr-header align-center>
          <v-flex>
            <h2 class="text-dark my-0 f-size-22">
              <span class="text-red fw-800">{{
                tree.countDonations | FormatNumber
              }}</span>
              <span class="fw-300 ml-2">{{
                tree.countDonations == 1 ? "doação" : "doações"
              }}</span>
            </h2>
            <h5 class="text-dark fw-400 f-size-16">
              <b>{{ tree.countCampaigns | FormatNumber }}</b>
              {{ tree.countCampaigns == 1 ? "campanha" : "campanhas" }} -
              <b>{{ tree.countProjects | FormatNumber }}</b>
              {{ tree.countProjects == 1 ? "projeto" : "projetos" }} -
              <b>{{ tree.countCarts | FormatNumber }}</b>
              {{ tree.countCarts == 1 ? "carrinho" : "carrinhos" }}
            </h5>
          </v-flex>
          <v-flex
            text-xs-right
            v-if="pageNumber < cashFlowPagesEnum.ConciliedAUC"
          >
            <div class="caption text-color">Total pago</div>
            <h4 class="fw-700 f-size-18 text-dark">
              {{ tree.totalToConciliate | FormatMoney }}
            </h4>
          </v-flex>
          <v-flex
            text-xs-right
            shrink
            pl-5
            v-if="
              showTaxProjectIncentived &&
              paymentMethod != paymentMethodEnum.PayrollDeduction
            "
          >
            <div class="caption text-color">Taxas a Abater</div>
            <h4 class="fw-300 f-size-18 text-red">
              {{ tree.taxProjectIncentivedOrAucOrCompany | FormatMoney }}
            </h4>
          </v-flex>
          <v-flex
            text-xs-right
            shrink
            pl-5
            v-if="
              paymentMethod == paymentMethodEnum.Ticket &&
              showTaxProjectNotIncentived
            "
          >
            <div class="caption text-color">Taxas Abatidas</div>
            <h4 class="fw-300 f-size-18 text-blue">
              {{ tree.taxProjectWithoutIncentiveLawOrPerson | FormatMoney }}
            </h4>
          </v-flex>
          <v-flex text-xs-right shrink pl-5>
            <div class="caption text-color">Total a transferir</div>
            <h4 class="fw-300 f-size-18 text-dark">
              {{ tree.totalToTransfer | FormatMoney }}
            </h4>
          </v-flex>
        </v-layout>

        <!-- action bar ############################################################ -->
        <v-layout action-bar align-center :class="verifyScroll ? 'fixed' : ''">
          <v-flex shrink style="width: 64px">
            <v-checkbox
              :indeterminate="tree.indeterminate"
              class="ma-0 justify-end"
              v-model="tree.checked"
              @change="changeCheckbox(tree)"
              v-if="enableCheckbox && tree.canSelectAllChildrenNodes"
            ></v-checkbox>
          </v-flex>
          <v-flex grow pl-3>
            <v-btn
              flat
              fab
              small
              class="btn-ghost-red"
              title="Excluir"
              v-if="
                paymentMethod == paymentMethodEnum.Ticket &&
                pageNumber == cashFlowPagesEnum.OnConciliationAUC
              "
              @click="confirmDeleteTicket"
            >
              <faIcon type="fas" name="trash" size="16px" />
            </v-btn>
            <v-btn
              flat
              color="white"
              class="btn-yellow"
              v-if="
                paymentMethod == paymentMethodEnum.Ticket &&
                pageNumber == cashFlowPagesEnum.OnConciliationAUC
              "
              @click="confirmResendTicket()"
            >
              <faIcon
                type="fas"
                name="sync-alt"
                size="16px"
                class="mr-2"
              />Reenviar Boleto
            </v-btn>
            <v-btn
              flat
              color="white"
              class="btn-primary"
              v-if="enableConcilied"
              @click="confirmConcilied()"
            >
              <faIcon
                type="fas"
                name="box-check"
                size="16px"
                class="mr-2"
              />Conciliado
            </v-btn>
            <v-btn
              flat
              small
              color="white"
              class="btn-red px-3"
              title="Rejeitar"
              v-if="pageNumber == cashFlowPagesEnum.PendentAcceptToInstitution"
              @click="confirmDeclinePendingTransfer()"
            >
              <faIcon
                type="fas"
                class="mr-2"
                name="thumbs-down"
                size="16px"
              />Rejeitar
            </v-btn>
            <v-btn
              flat
              small
              color="white"
              class="btn-primary px-3"
              title="Aceitar"
              v-if="pageNumber == cashFlowPagesEnum.PendentAcceptToInstitution"
              @click="confirmAcceptPendingTransfer()"
            >
              <faIcon
                type="fas"
                class="mr-2"
                name="thumbs-up"
                size="16px"
              />Aceitar
            </v-btn>
            <v-btn
              flat
              small
              color="white"
              class="btn-primary px-3"
              title="Recebido"
              v-if="pageNumber == cashFlowPagesEnum.OnConciliationInstitution"
              @click="confirmConciliedInstitution()"
            >
              <faIcon
                type="fas"
                class="mr-2"
                name="thumbs-up"
                size="16px"
              />Recebibo
            </v-btn>
            <v-btn
              flat
              small
              color="white"
              class="btn-primary px-3"
              title="Recebido"
              @click="confirmInformSportMinistery()"
              v-if="pageNumber == cashFlowPagesEnum.Concilied"
            >
              <faIcon
                type="fas"
                class="mr-2"
                name="thumbs-up"
                size="16px"
              />Informar Ministério do Esporte
            </v-btn>
            <v-btn
              flat
              small
              color="white"
              class="btn-primary px-3"
              title="Transferir"
              v-if="enableTransfer"
              @click="confirmTransfer()"
            >
              <faIcon type="fas" class="mr-2" name="thumbs-up" size="16px" />
              {{ transferName }}
            </v-btn>
          </v-flex>

          <!-- # search -->
          <v-layout
            fr-search
            align-center
            :class="openInputSearch ? 'active' : ''"
          >
            <v-flex>
              <InputText
                type="search"
                place-holder="Busque por palavra-chave"
                textLabel
                v-model="searchText"
              />
            </v-flex>
          </v-layout>

          <!-- #btn-search -->
          <v-flex shrink>
            <v-btn
              fab
              flat
              small
              color="white"
              class="btn-default_blue-light mr-2"
              @click="
                openInputSearch = !openInputSearch;
                searchText = '';
              "
            >
              <faIcon
                type="fas"
                name="search"
                size="16px"
                v-if="!openInputSearch"
              />
              <faIcon type="fas" name="times" size="16px" v-else />
            </v-btn>
          </v-flex>

          <!-- #btn-download -->
          <v-flex shrink v-if="enableDownloadExcelCsv || enableDownloadDbf">
            <v-menu offset-y left>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  flat
                  small
                  color="white"
                  class="btn-gray mr-2"
                  v-on="on"
                >
                  <Icon name="fas fa-download" size="16px" />
                </v-btn>
              </template>
              <v-list>
                <v-list-tile @click="exportExcel" v-if="enableDownloadExcelCsv">
                  <v-list-tile-title class="text-dark">
                    <Icon
                      name="fal fa-file-excel mr-0"
                      size="16"
                      colorFont="#c3c6d5"
                    />Excel
                  </v-list-tile-title>
                </v-list-tile>
                <!-- <v-list-tile @click="exportCSV" v-if="enableDownloadExcelCsv">
                  <v-list-tile-title class="text-dark">
                    <Icon name="fal fa-file-csv mr-0" size="16" colorFont="#c3c6d5" />CSV
                  </v-list-tile-title>
                </v-list-tile> -->
                <v-list-tile @click="exportDBF" v-if="enableDownloadDbf">
                  <v-list-tile-title class="text-dark">
                    <Icon
                      name="fal fa-file-excel mr-0"
                      size="16"
                      colorFont="#c3c6d5"
                    />Exportar DBF
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-flex>

          <!-- #btn-filters -->
          <v-flex shrink>
            <v-btn
              fab
              flat
              small
              color="white"
              class="btn-default mr-0"
              @click="checkOpenModalFilters()"
            >
              <faIcon type="fas" name="filter" size="16px" />
            </v-btn>
          </v-flex>
        </v-layout>
        <!-- / action bar ############################################################ -->

        <!--  list #########################################-->
        <div class="fr-list">
          <template>
            <v-expansion-panel>
              <!-- Campanhas -->
              <v-expansion-panel-content
                v-for="(child1, i) in filteredListLevel1"
                :key="i"
              >
                <template v-slot:header>
                  <v-layout align-center>
                    <v-flex shrink pr-2>
                      <v-checkbox
                        v-if="
                          enableCheckbox && child1.canSelectAllChildrenNodes
                        "
                        class="ma-0"
                        :indeterminate="child1.indeterminate"
                        :value="child1.checked"
                        v-model="child1.checked"
                        @change="changeCheckbox(child1)"
                        @click.native.stop
                      ></v-checkbox>
                    </v-flex>
                    <v-flex mw-half>
                      <h4>{{ child1.description }}</h4>
                    </v-flex>
                    <v-flex
                      text-xs-right
                      text-gray-dark
                      f-size-16
                      pl-6
                      nowrap
                      v-if="pageNumber < cashFlowPagesEnum.ConciliedAUC"
                    >
                      <div class="caption">Total pago</div>
                      {{ child1.totalToConciliate | FormatMoney }}
                    </v-flex>
                    <v-flex
                      text-xs-right
                      text-red
                      f-size-16
                      nowrap
                      v-if="
                        showTaxProjectIncentived &&
                        paymentMethod != paymentMethodEnum.PayrollDeduction
                      "
                    >
                      <div class="caption">Taxas a Abater</div>
                      {{
                        child1.taxProjectIncentivedOrAucOrCompany | FormatMoney
                      }}
                    </v-flex>
                    <v-flex
                      text-xs-right
                      text-blue
                      f-size-16
                      nowrap
                      v-if="
                        paymentMethod == paymentMethodEnum.Ticket &&
                        showTaxProjectNotIncentived
                      "
                    >
                      <div class="caption">Taxas Abatidas</div>
                      {{
                        child1.taxProjectWithoutIncentiveLawOrPerson
                          | FormatMoney
                      }}
                    </v-flex>
                    <v-flex
                      text-xs-right
                      text-color-dark
                      fw-600
                      f-size-16
                      nowrap
                    >
                      <div class="caption">Total a transferir</div>
                      {{ child1.totalToTransfer | FormatMoney }}
                    </v-flex>
                    <v-flex shrink details>
                      <v-btn
                        v-if="enableOpenModalUser"
                        flat
                        color="white"
                        class="btn-default btn-square mr-0"
                        small
                        @click.native.stop
                      >
                        <faIcon type="fal" name="search-plus" size="16px" />
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </template>
                <v-card>
                  <v-card-text style="padding-left: 42px !important">
                    <v-expansion-panel focusable>
                      <v-expansion-panel-content
                        :expand-icon="level > 2 ? $expand : ''"
                        v-for="(child2, i) in child1.children"
                        :key="i"
                      >
                        <template v-slot:header>
                          <v-layout align-center>
                            <v-flex shrink pr-2>
                              <v-checkbox
                                class="ma-0"
                                :indeterminate="child2.indeterminate"
                                v-model="child2.checked"
                                :value="child2.checked"
                                @change="changeCheckbox(child2)"
                                @click.native.stop
                                v-if="
                                  enableCheckbox &&
                                  child2.canSelectAllChildrenNodes
                                "
                              ></v-checkbox>
                            </v-flex>
                            <v-flex mw-half>
                              <h5>{{ child2.description }}</h5>
                            </v-flex>
                            <v-flex
                              text-xs-right
                              text-gray-dark
                              f-size-16
                              nowrap
                              v-if="pageNumber < cashFlowPagesEnum.ConciliedAUC"
                              >{{
                                child2.totalToConciliate | FormatMoney
                              }}</v-flex
                            >
                            <v-flex
                              text-xs-right
                              text-red
                              f-size-16
                              nowrap
                              v-if="
                                showTaxProjectIncentived &&
                                paymentMethod !=
                                  paymentMethodEnum.PayrollDeduction
                              "
                              >{{
                                child2.taxProjectIncentivedOrAucOrCompany
                                  | FormatMoney
                              }}</v-flex
                            >
                            <v-flex
                              text-xs-right
                              text-blue
                              f-size-16
                              nowrap
                              v-if="
                                paymentMethod == paymentMethodEnum.Ticket &&
                                showTaxProjectNotIncentived
                              "
                              >{{
                                child2.taxProjectWithoutIncentiveLawOrPerson
                                  | FormatMoney
                              }}</v-flex
                            >
                            <v-flex
                              text-xs-right
                              text-color-dark
                              fw-600
                              f-size-16
                              nowrap
                              >{{
                                child2.totalToTransfer | FormatMoney
                              }}</v-flex
                            >
                            <v-flex shrink details>
                              <v-btn
                                flat
                                color="white"
                                class="btn-default btn-square mr-0"
                                small
                                v-if="enableModalLevel2"
                                @click="openModalLevel2(child2)"
                              >
                                <faIcon
                                  type="fal"
                                  name="search-plus"
                                  size="16px"
                                />
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </template>
                        <v-card v-if="level >= 3">
                          <v-card-text
                            class="level3 flex-column"
                            v-if="pageNumber == cashFlowPagesEnum.ConciliedAUC"
                          >
                            <div
                              :expand-icon="''"
                              v-for="(child3, i) in child2.children"
                              :key="i"
                            >
                              <v-layout align-center>
                                <v-flex shrink pr-2>
                                  <v-checkbox
                                    class="ma-0"
                                    :indeterminate="child3.indeterminate"
                                    v-model="child3.checked"
                                    :value="child3.checked"
                                    @change="changeCheckbox(child3)"
                                    @click.native.stop
                                    v-if="
                                      enableCheckbox &&
                                      child3.canSelectAllChildrenNodes
                                    "
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex mw-half>
                                  <h5>
                                    #{{ child3.cartId }} -
                                    {{ child3.description }}
                                  </h5>
                                </v-flex>
                                <v-flex
                                  mr-3
                                  text-xs-right
                                  text-color-dark
                                  fw-600
                                  f-size-16
                                  nowrap
                                  >{{
                                    child3.totalToTransfer | FormatMoney
                                  }}</v-flex
                                >
                              </v-layout>
                            </div>
                          </v-card-text>
                          <v-card-text class="level3 flex-column" v-else>
                            <div
                              :expand-icon="''"
                              v-for="(child3, i) in child2.campaignLevel3"
                              :key="i"
                            >
                              <v-layout pa-3>
                                <v-flex mw-half>
                                  <h5>{{ child3.description }}</h5>
                                </v-flex>
                                <v-flex
                                  mr-3
                                  text-xs-right
                                  text-color-dark
                                  fw-600
                                  f-size-16
                                  nowrap
                                  >{{
                                    child3.totalToTransfer | FormatMoney
                                  }}</v-flex
                                >
                              </v-layout>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </div>
        <button
          class="btn-ghost-primary v-btn v-btn--flat white--text v-btn-round mx-auto my-3 d-block"
          type="button"
          v-if="
            paginate &&
            cashFlowFilters.pagesCount > 0 &&
            cashFlowFilters.pagesCount >= cashFlowFilters.page
          "
          @click="showMore()"
        >
          <Icon name="fas fa-search" size="16" class="mr-2" />Mostrar Mais
        </button>
      </div>
    </div>

    <ModalFiltersMultSelect
      v-model="openModalFilters"
      :cashFlowFiltersProp="cashFlowFilters"
      :pageNumber="pageNumber"
      :paymentMethod="paymentMethod"
      @filter="applyFilter"
      @close="openModalFilters = false"
    ></ModalFiltersMultSelect>

    <ModalFiltersStatusZero
      v-model="openModalFiltersStatusZero"
      :cashFlowFiltersProp="tree.filter"
      :pageNumber="pageNumber"
      @filter="applyFilter"
      @close="openModalFiltersStatusZero = false"
    ></ModalFiltersStatusZero>

    <ModalTransferCompanyToAuc
      ref="modalConfirmTransferCompanyToAuc"
      @transfer="transfer"
      :pageNumber="pageNumber"
    />

    <AucAlert
      ref="aucAlert"
      :title="aucAlertText"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="executeAction()"
      @cancel="resetCheckbox"
    />
    <!-- #modal project details  -->
    <ModalUserProjectDonations
      ref="modalUserProjectDonations"
      :node="nodeSelected"
    ></ModalUserProjectDonations>

    <!-- Modal userDonations -->
    <ModalUserDonations
      ref="modalUserDonations"
      :node="nodeSelected"
      :paymentMethod="paymentMethod"
      @resendTicket="confirmResendTicket"
      @deleteTicket="confirmDeleteTicket"
      @conciliateTicket="confirmConcilied"
    ></ModalUserDonations>
  </div>
</template>
<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import ModalUserProjectDonations from "@/views/app/resourceFlow/modal/ModalUserProjectDonations.vue";
import ModalUserDonations from "@/views/app/resourceFlow/modal/ModalUserDonations.vue";
import ModalFiltersMultSelect from "@/views/app/resourceFlow/modal/ModalFiltersMultSelect.vue";
import ModalFiltersStatusZero from "@/views/app/resourceFlow/modal/ModalFiltersStatusZero.vue";
import ModalTransferCompanyToAuc from "@/views/app/resourceFlow/modal/ModalTransferCompanyToAuc.vue";
import CashFlowService from "@/scripts/services/cashFlow.service.js";
import DonationConciliationStatus from "@/scripts/models/enums/donationConciliationStatus.enum";
import EntityService from "@/scripts/services/entity.service";
import MinisteryService from "@/scripts/services/ministery.service";
import ReportService from "@/scripts/services/report.service";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { Node, Tree } from "@/scripts/models/tree.model";
import {
  CashFlow,
  CashFlowFilters,
  CashFlowListLevel1,
} from "@/scripts/models/cashFlow.model";
import { Excel, Csv } from "@/scripts/models/export.model";
import CashFlowTransferDonation from "@/scripts/models/cashFlowTransferDonation.model";
import { CashFlowPages } from "@/scripts/models/enums/cashFlowPages.enum";
import Permissions from "@/scripts/models/enums/permissions.enum";
import CashFlowStatusZeroFilters from "@/scripts/models/cashFlowStatusZeroFilters.model";

export default {
  components: {
    ModalUserDonations,
    ModalFiltersMultSelect,
    ModalFiltersStatusZero,
    ModalUserProjectDonations,
    ModalTransferCompanyToAuc,
    AucAlert,
  },
  props: {
    pageOptions: null,
    enableDownloadExcelCsv: Boolean,
    enableDownloadDbf: Boolean,
    enableModalLevel2: Boolean,
    enableAcceptDecline: Boolean,
    enableCheckbox: Boolean,
    enableOpenModalUser: Boolean,
    enableTransfer: Boolean,
    enableConcilied: Boolean,
    enableConcilied: Boolean,
    enableMultiView: Boolean,
    level: Number,
    paymentMethod: Number,
    statusConciliation: Number,
    statusTransferResource: Number,
    levelData: Object,
    pageNumber: Number,
    showTaxProjectIncentived: Boolean,
    showTaxProjectNotIncentived: Boolean,
    selectOnlyOne: Boolean,
    paginate: Boolean,
    typeView: {
      type: String,
      default: "institution",
    },
  },
  data() {
    return {
      verifyScroll: false,
      openModalFilters: false,
      openModalFiltersStatusZero: false,
      openInputSearch: false,
      openModalUserProjectDonations: false,
      openModalConfirmTransferCompanyToAuc: true,
      modalDonationsPerson: false,
      cashFlowService: new CashFlowService(),
      ministeryService: new MinisteryService(),
      entityService: new EntityService(),
      reportService: new ReportService(),
      cashFlow: new CashFlow(),
      cashFlowFilters: new CashFlowFilters(),
      cashFlowFiltersStatusZero: new CashFlowStatusZeroFilters(),
      donations: [],
      tree: null,
      actionSelected: "",
      nodeSelected: new Node(),
      sumDonationsSelected: 0,
      listDonationsId: [],
      listCartsId: [],
      listTicketCartIds: [],
      listCartItemsIds: [],
      listSelectedItems: [],
      listDonationCartItemCashFlowStatus: [],
      cashFlowProjectSelected: [],
      checkboxSelectAll: false,
      checkboxProjectsSelected: [],
      checkboxCampaignSelected: [],
      cashFlowTransferDonations: [],
      controllCheckboxes: [],
      aucAlertText: "",
      searchText: "",
      paymentMethodEnum: "",
      cashFlowPagesEnum: "",
      header: [
        "ID Doação",
        "CPF",
        "ID do Cliente",
        "Nome",
        "Email",
        "Valor da doação",
        "Nome do Projeto",
        "Nome da Campanha",
      ],
      transferName: "",
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.paymentMethodEnum = PaymentMethod;
    this.cashFlowPagesEnum = CashFlowPages;
    if (this.pageNumber == CashFlowPages.ConciliedAUC)
      this.transferName = "Transferido";
    if (this.pageNumber == CashFlowPages.PendentTransferToInstitution)
      this.transferName = "A transferir";
    else this.transferName = "Transferir";
  },
  watch: {
    paymentMethod: {
      immediate: true,
      handler(newVal, oldVal) {
        this.cashFlowFilters.paymentMethod = this.paymentMethod;
        this.cashFlowFilters.paginate = this.paginate;
        this.cashFlowFilters.typeView = this.typeView;
        this.cashFlowFilters.page = 1;
        this.loadDonations();
      },
    },
    typeView: {
      handler(newVal, oldVal) {
        this.cashFlowFilters.typeView = newVal;
        this.loadDonations();
      },
    },
  },
  computed: {
    filteredListLevel1() {
      if (this.tree.children.length == 0) return [];
      let result = this.tree.children.filter((campaign) => {
        return campaign.description
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
      result = result.sort((a, b) =>
        a.description.trim().localeCompare(b.description.trim())
      );
      return result;
    },
  },
  methods: {
    checkOpenModalFilters() {
      if (this.pageNumber == CashFlowPages.StatusZero) {
        this.openModalFiltersStatusZero = !this.openModalFiltersStatusZero;
      } else {
        this.openModalFilters = !this.openModalFilters;
      }
    },
    changeCheckbox(node) {
      this.checkRecursion(node);
    },
    sortChildren(children) {
      if (children)
        children = children.sort((a, b) =>
          a.description.trim().localeCompare(b.description.trim())
        );
      return children;
    },
    checkRecursion(node) {
      if (node.checked && node.father != null) {
        node.father.countChildrenChecked++;
        if (node.father.children.length == node.father.countChildrenChecked) {
          node.father.checked = true;
          node.father.indeterminate = false;
        } else {
          node.father.indeterminate = true;
        }
      } else if (!node.checked && node.father != null) {
        node.father.countChildrenChecked--;
        node.father.checked = false;
        if (node.father.countChildrenChecked <= 0)
          node.father.indeterminate = false;
      }

      if (node.children != null)
        node.children.forEach((child) => {
          child.checked = node.checked;
          this.checkRecursion(child);
        });
    },
    resetCheckbox() {
      this.tree.checked = false;
      this.checkRecursion(this.tree);
      this.listCartItemsIds = [];
      this.listDonationCartItemCashFlowStatus = [];
      this.listCartsId = [];
      this.listDonationsId = [];
      this.listTicketCartIds = [];
    },
    getCheckedDonations() {
      this.listDonationsId = [];
      this.listCartsId = [];
      this.listTicketCartIds = [];
      this.listCartItemsIds = [];
      this.listDonationCartItemCashFlowStatus = [];
      this.listSelectedItems = [];
      this.sumDonationsSelected = 0;
      this.tree.children.forEach((child1) => {
        child1.children.forEach((child2) => {
          child2.children.forEach((child3) => {
            debugger;
            if (child3.checked) {
              this.listDonationsId = this.listDonationsId.concat(
                child3.donationsId
              );
              this.listCartsId.push(child3.cartId);
              this.listTicketCartIds.push(child3.ticketCartDetailId);
              this.listCartItemsIds.push(child3.cartItemId);
              let selectedItem = {
                cartId: child3.cartId,
                ticketCartDetailId: child3.ticketCartDetailId,
                donationsId: child3.donationsId,
                cartItemId: child3.cartItemId,
                donationCartItemCashFlowStatusId:
                  child3.donationCartItemCashFlowStatusId,
              };
              this.listSelectedItems.push(selectedItem);
              this.sumDonationsSelected += child3.donationValue;
            }
          });
        });
      });
    },
    showMore() {
      this.cashFlowFilters.page++;
      this.loadDonations();
    },
    loadDonations() {
      if (this.pageNumber != CashFlowPages.ConciliedAUC)
        this.cashFlowFilters.paymentMethod = this.paymentMethod;
      this.cashFlowFilters.entityId = this.$route.query.entityId;
      this.cashFlowFilters.statusConciliation = this.statusConciliation;
      this.cashFlowFilters.statusTransferResource = this.statusTransferResource;
      this.cashFlowFilters.pageNumber = this.pageNumber;
      let cashFlowFiltersRequest = Object.assign({}, this.cashFlowFilters);
      cashFlowFiltersRequest.listCampaign = [];
      cashFlowFiltersRequest.listCompanies = [];
      cashFlowFiltersRequest.listProjects = [];
      if (this.pageNumber != CashFlowPages.StatusZero) {
        this.cashFlowService
          .listAllToConciliate(cashFlowFiltersRequest)
          .then(this.loadDonationsCallback);
      } else {
        this.cashFlowFiltersStatusZero.entityId = this.$route.query.entityId;
        this.cashFlowService
          .dataStatusZero(this.cashFlowFiltersStatusZero)
          .then(this.loadDonationsCallback);
      }
    },
    loadDonationsCallback(data) {
      if (this.cashFlowFilters.page == 1) {
        this.tree = data;
      } else {
        this.tree.children.push(...data.children);
        this.tree.filter = data.filter;
      }

      this.cashFlowFilters.listCampaign = this.tree.children;
      this.cashFlowFilters.listCompanies = this.tree.filter.companies;
      this.cashFlowFilters.listProjects = this.tree.filter.projects;
      this.cashFlowFilters.page = this.tree.filter.page;
      this.cashFlowFilters.pagesCount = this.tree.filter.pagesCount;

      this.tree.canSelectAllChildrenNodes =
        this.paymentMethod == PaymentMethod.Ticket ||
        this.pageNumber == CashFlowPages.ConciliedAUC
          ? false
          : true;

      this.tree.children.forEach((child1) => {
        child1.father = this.tree;
        child1.canSelectAllChildrenNodes =
          this.paymentMethod ==
          (PaymentMethod.Ticket ||
            this.pageNumber == CashFlowPages.ConciliedAUC)
            ? false
            : true;
        child1.children = this.sortChildren(child1.children);
        child1.children.forEach((child2) => {
          child2.canSelectAllChildrenNodes =
            this.paymentMethod == PaymentMethod.PayrollDeduction &&
            this.pageNumber == CashFlowPages.OnConciliationAUC
              ? false
              : true;
          child2.father = child1;
        });
      });
    },
    handleScroll(event) {
      event.path[1].window.scrollY > 273
        ? (this.verifyScroll = true)
        : (this.verifyScroll = false);
    },
    applyFilter(cashFlowFilters) {
      if (this.pageNumber != CashFlowPages.StatusZero) {
        this.cashFlowFilters.companyId = cashFlowFilters.companyId;
        this.cashFlowFilters.campaignId = cashFlowFilters.campaignId;
        this.cashFlowFilters.projectId = cashFlowFilters.projectId;
        this.cashFlowFilters.projectType = cashFlowFilters.projectType;
        this.cashFlowFilters.companiesIdsSelected =
          cashFlowFilters.companiesIdsSelected;
        this.cashFlowFilters.projectIdsSelected =
          cashFlowFilters.projectIdsSelected;
        this.cashFlowFilters.typeProjectsIdSelected =
          cashFlowFilters.typeProjectsIdSelected;
        if (this.pageNumber == CashFlowPages.ConciliedAUC)
          this.cashFlowFilters.paymentMethod = cashFlowFilters.paymentMethod;
        this.cashFlowFilters.initialDate = cashFlowFilters.initialDate;
        this.cashFlowFilters.finalDate = cashFlowFilters.finalDate;
        this.cashFlowFilters.initialDateConciliation =
          cashFlowFilters.initialDateConciliation;
        this.cashFlowFilters.finalDateConciliation =
          cashFlowFilters.finalDateConciliation;
      } else {
        this.cashFlowFiltersStatusZero = cashFlowFilters;
      }
      this.cashFlowFilters.page = 1;
      this.loadDonations();
      this.openModalFilters = false;
      this.openModalFiltersStatusZero = false;
    },
    openModalProjectDonations(node) {
      this.nodeSelected = node;
      this.$refs.modalUserProjectDonations.open();
    },
    openModalUserDonations(node) {
      this.nodeSelected = node;
      this.$refs.modalUserDonations.open();
    },
    openModalLevel2(node) {
      if (this.pageNumber == CashFlowPages.OnConciliationAUC)
        this.openModalUserDonations(node);
      else this.openModalProjectDonations(node);
    },
    resendTicket() {
      this.cashFlowService
        .resendTicket(this.listCartsId)
        .then(this.loadDonations);
    },
    deleteTicket() {
      this.cashFlowService
        .deleteTicket(this.listCartsId)
        .then(this.loadDonations);
    },
    exportExcel() {
      this.cashFlowFilters.entityId = this.$route.query.entityId;
      this.reportService
        .cashFlowReport(this.cashFlowFilters)
        .then((response) => {
          if (response != null) {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/excel" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Relatório_Fluxo_Financeiro" +
                this.$moment().format("YYYY-MM-DD_H:m") +
                ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
          } else {
          }
        });
      // var props = this.mountProps();
      // var data = this.mountDataToExport();
      // var excel = new Excel(props);
      // excel.setData(data);
      // excel.setHeader(this.getExportHeaderBasedOnPageNumber()); //this.header);
      // var blob = excel.exportToBlob();
      // this.export(blob, "xlsx", "BaseAConciliar");
    },
    exportCSV() {
      var data = this.mountDataToExport();
      var csv = new Csv();
      csv.setData(data);
      csv.setHeader(this.getExportHeaderBasedOnPageNumber()); //this.header);
      var blob = csv.exportToBlob();
      this.export(blob, "csv", "BaseAConciliar");
    },
    exportDBF() {
      this.cashFlowService.downloadDbf(this.$route.query.entityId).then(
        function (response) {
          debugger;
          if (response != null) {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/excel" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "DBF" + this.$moment().format("YYYY-MM-DD_H:m") + ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
          } else {
          }
        }.bind(this)
      );
    },
    getExportHeaderBasedOnPageNumber() {
      if (this.pageNumber == CashFlowPages.OnConciliationAUC) {
        return [
          "ID Doação",
          "CPF",
          "ID do Cliente",
          "Nome",
          "Email",
          "Valor da doação",
          "Meio de Pagamento",
          "Nome da Campanha",
          "Fornecedor",
          "Taxas a abater",
          "Taxas abatidas",
        ];
      } else if (this.pageNumber == CashFlowPages.TransferToAUC) {
        return [
          "ID Doação",
          "CPF",
          "ID do Cliente",
          "Nome",
          "Email",
          "Valor da doação",
          "Meio de Pagamento",
          "Nome da Empresa",
          "Nome da Campanha",
          "Fornecedor",
        ];
      } else if (this.pageNumber == CashFlowPages.StatusZero) {
        return [
          "ID Doação",
          "CPF",
          "ID do Cliente",
          "Nome",
          "Email",
          "Telefone",
          "Valor da doação",
          "Meio de Pagamento",
          "Nome do Projeto",
          "Nome da Campanha",
          "Fornecedor",
          "Taxas a abater",
          "Taxas abatidas",
        ];
      } else {
        return [
          "ID Doação",
          "CPF",
          "ID do Cliente",
          "Nome",
          "Email",
          "Valor da doação",
          "Meio de Pagamento",
          "Nome do Projeto",
          "Nome da Campanha",
          "Fornecedor",
          "Taxas a abater",
          "Taxas abatidas",
        ];
      }
    },
    getExportLineBasedOnPageNumber(campaign, project, person) {
      if (this.pageNumber == CashFlowPages.OnConciliationAUC) {
        console.log(person);
        //no project description
        return [
          person.cartId,
          person.cpf,
          person.personName,
          person.email,
          this.$options.filters.FormatMoney(person.donationValue),
          person.paymentMethodText,
          person.code,
          campaign.description,
          person.providerName,
          person.taxProjectIncentivedOrAucOrCompany,
          person.taxProjectWithoutIncentiveLawOrPerson,
        ];
      } else if (this.pageNumber == CashFlowPages.StatusZero) {
        console.log(person);
        return [
          person.cartId,
          person.cpf,
          person.personName,
          person.email,
          person.phone,
          this.$options.filters.FormatMoney(person.donationValue),
          person.paymentMethodText,
          person.code,
          project.description,
          campaign.description,
          person.providerName,
          person.taxProjectIncentivedOrAucOrCompany,
          person.taxProjectWithoutIncentiveLawOrPerson,
        ];
      }
      return [
        person.cartId,
        person.cpf,
        person.personName,
        person.email,
        this.$options.filters.FormatMoney(person.donationValue),
        person.paymentMethodText,
        person.code,
        project.description,
        campaign.description,
        person.providerName,
        person.taxProjectIncentivedOrAucOrCompany,
        person.taxProjectWithoutIncentiveLawOrPerson,
      ];
    },
    mountDataToExport() {
      let table = [];
      this.tree.children.forEach((campaign) => {
        campaign.children.forEach((project) => {
          project.children.forEach((person) => {
            let line = this.getExportLineBasedOnPageNumber(
              campaign,
              project,
              person
            );
            table.push(line);
          });
        });
      });
      return table;
    },
    mountProps() {
      return {
        Title: "Base de Conciliação",
        Subject: "Fluxo Financeiro - Base de Conciliação",
        Author: "Abrace uma Causa",
        CreatedDate: new Date(),
      };
    },
    export(blob, type, name = "dados") {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, name + ".csv");
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = name + "." + type;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    checkNode(node) {
      this.resetCheckbox();
      node.checked = true;
      this.checkRecursion(node);
      this.confirmResendTicket();
    },
    executeAction() {
      switch (this.actionSelected) {
        case "confirmTransferToAuc":
          this.$refs.modalConfirmTransferCompanyToAuc.open();
          break;
        case "resendTicket":
          this.resendTicket();
          break;
        case "deleteTicket":
          this.deleteTicket();
          break;
        case "conciliateDonations":
          this.conciliateDonations();
          break;
        case "transferToInstitutionAccept":
          this.transferToInstitutionAccept();
          break;
        case "transferToInstitution":
          this.$refs.modalConfirmTransferCompanyToAuc.open();
          break;
        case "declinePendingTransfer":
          this.declinePendingTransfer();
          break;
        case "acceptPendingTransfer":
          this.acceptPendingTransfer();
          break;
        case "conciliedInstitution":
          this.conciliedInstitution();
        case "informSportMnistery":
          this.informSportMnistery();
          break;
        default:
          break;
      }
    },
    confirmTransfer() {
      if (this.pageNumber == CashFlowPages.ConciliedAUC) {
        this.confirmTransferToInstitutionAccept();
      } else if (this.pageNumber == CashFlowPages.TransferToAUC) {
        this.confirmTransferToAuc();
      } else if (
        this.pageNumber == CashFlowPages.PendentTransferToInstitution
      ) {
        this.confirmTransferToInstitution();
      }
    },
    confirmDeclinePendingTransfer() {
      this.getCheckedDonations();
      this.actionSelected = "declinePendingTransfer";
      this.aucAlertText =
        "Você tem certeza de que gostaria de rejeitar a transferência no valor de " +
        this.$options.filters.FormatMoney(this.sumDonationsSelected) +
        "?";
      this.$refs.aucAlert.open();
    },
    confirmConciliedInstitution() {
      this.getCheckedDonations();
      this.actionSelected = "conciliedInstitution";
      this.aucAlertText =
        "Você tem certeza de que gostaria de confirmar que recebeu a transferência no valor de " +
        this.$options.filters.FormatMoney(this.sumDonationsSelected) +
        "?";
      this.$refs.aucAlert.open();
    },
    confirmInformSportMinistery() {
      this.getCheckedDonations();
      this.actionSelected = "informSportMnistery";
      this.aucAlertText =
        "Você tem certeza que gostaria de informar o Mnistério do Esporte sobre as doações selecionadas?";
      this.$refs.aucAlert.open();
    },
    confirmAcceptPendingTransfer() {
      this.getCheckedDonations();
      this.actionSelected = "acceptPendingTransfer";
      this.aucAlertText =
        "Você tem certeza de que gostaria de aceitar a transferência no valor de " +
        this.$options.filters.FormatMoney(this.sumDonationsSelected) +
        "?";
      this.$refs.aucAlert.open();
    },
    confirmTransferToInstitution() {
      this.getCheckedDonations();
      this.actionSelected = "transferToInstitution";
      this.aucAlertText =
        "Você tem certeza de que gostaria de confirmar que a transferência no valor de " +
        this.$options.filters.FormatMoney(this.sumDonationsSelected) +
        " foi efetuada?";
      this.$refs.aucAlert.open();
    },
    confirmTransferToInstitutionAccept() {
      this.getCheckedDonations();
      this.actionSelected = "transferToInstitutionAccept";
      this.aucAlertText =
        "Você tem certeza de que gostaria de informar que existe uma transferência pendente de aceite para a instituição no valor de " +
        this.$options.filters.FormatMoney(this.sumDonationsSelected) +
        "?";
      this.$refs.aucAlert.open();
    },
    confirmTransferToAuc() {
      this.getCheckedDonations();
      this.actionSelected = "confirmTransferToAuc";
      this.aucAlertText =
        "Você tem certeza de que gostaria de confirmar que a transferência no valor de " +
        this.$options.filters.FormatMoney(this.sumDonationsSelected) +
        " foi efetuada?";
      this.$refs.aucAlert.open();
    },
    confirmResendTicket(node = null) {
      if (node != null) this.checkNode(node);
      this.getCheckedDonations();
      this.actionSelected = "resendTicket";
      this.aucAlertText =
        "Você tem certeza de que gostaria de confirmar o reenvio de boletos?";
      this.$refs.aucAlert.open();
    },
    confirmDeleteTicket(node = null) {
      if (node != null) this.checkNode(node);
      this.getCheckedDonations();
      this.actionSelected = "deleteTicket";
      this.aucAlertText =
        "Você tem certeza de que gostaria de deletar boletos no valor de " +
        this.$options.filters.FormatMoney(this.sumDonationsSelected) +
        " ?";
      this.$refs.aucAlert.open();
    },
    confirmConcilied(node = null) {
      if (node != null) this.checkNode(node);
      this.getCheckedDonations();
      this.actionSelected = "conciliateDonations";
      this.aucAlertText =
        "Você tem certeza de que gostaria de confirmar que o valor de " +
        this.$options.filters.FormatMoney(this.sumDonationsSelected) +
        " foi conciliado?";
      this.$refs.aucAlert.open();
    },
    informSportMnistery() {
      this.ministeryService
        .informSportMinistery(this.listSelectedItems)
        .then(this.loadDonations);
    },
    conciliedInstitution() {
      this.cashFlowService
        .conciliedToInstitution(this.listSelectedItems)
        .then(this.loadDonations);
    },
    conciliateDonations() {
      if (this.paymentMethod == PaymentMethod.PayrollDeduction) {
        this.cashFlowService
          .conciliatePayRollDeduction(
            this.listDonationsId,
            DonationConciliationStatus.ConciliedByAuc
          )
          .then(this.loadDonations);
      } else {
        this.cashFlowService
          .conciliateTicket(
            this.listTicketCartIds,
            DonationConciliationStatus.ConciliedByAuc
          )
          .then(this.loadDonations);
      }
    },
    transferToInstitutionAccept() {
      this.cashFlowService
        .transferToInstitutionAccept(this.listSelectedItems)
        .then(this.loadDonations);
    },
    transfer(cashFlowTransfer) {
      cashFlowTransfer = this.mountCashFlowTransferDonation(cashFlowTransfer);
      this.cashFlowService
        .transfer(cashFlowTransfer, this.listSelectedItems)
        .then(this.loadDonations);
    },
    declinePendingTransfer() {
      this.cashFlowService
        .declinePendingTransfer(this.listSelectedItems)
        .then(this.loadDonations);
    },
    acceptPendingTransfer() {
      this.cashFlowService
        .acceptPendingTransfer(this.listSelectedItems)
        .then(this.loadDonations);
    },
    mountCashFlowTransferDonation(cashFlowTransfer) {
      var array = [];
      this.listDonationsId.forEach((donationId) => {
        let cashFlowTransferDonation = new CashFlowTransferDonation();
        cashFlowTransferDonation.donationId = donationId;
        array.push(cashFlowTransferDonation);
      });
      cashFlowTransfer.cashFlowTransferDonations = array;
      return cashFlowTransfer;
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
