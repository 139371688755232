import File from "@/scripts/models/file.model";
export default class CashFlowTransfer {

	constructor() {
		this.id = 0;
		this.fileId = 0;
		this.transferDate = null;
		this.observation = '';
		this.type = 0;
		this.creationDate = '';
		this.updateDate = '';
		this.cashFlowTransferDonations = [];
		this.file = new File();
	}
}