import User from "@/scripts/models/user.model";
import Address from "@/scripts/models/address.model";

class CashFlow {

    constructor() {
        this.countDonations = 0;
        this.countCampaigns = 0;
        this.countProjects = 0;
        this.totalDonations = 0;
        this.totalTax = 0;
        this.campaignData = [];
        this.filters = [];
        this.selectedCampaigns = [];
    }

}

class CashFlowCampaign {
    constructor() {
        this.campaignId = 0;
        this.campaignName = "";
        this.totalCampaign = 0;
        this.totalTax = 0;
        this.totalToConciliate = 0;
        this.projectData = [];
        this.selectedProjects = [];
    }
}

class CashFlowProject {
    constructor() {
        this.projectId = 0;
        this.projectName = "";
        this.hasIncentiveLaw = false;
        this.totalProject = 0;
        this.totalTax = 0;
        this.totalToConciliate = 0;
        this.personDonations = [];
    }
}

class CashFlowPerson {
    constructor() {
        this.personId = 0;
        this.userId = 0;
        this.cartId = 0;
        this.personName = "";
        this.cpf = "";
        this.registrationId = 0;
        this.email = "";
        this.donationValue = 0;
    }
}

class CashFlowFilters {
    constructor() {
        this.entityId = 0;
        this.statusConciliation = 0;
        this.paymentMethod = 0;
        this.companyId = 0;
        this.campaignId = 0;
        this.projectId = 0;
        this.statusTransferResource = 0;
        this.initialFinalDates = [];
        this.listCompany = [];
        this.listCampaign = [];
        this.companies = [];
        this.page = 1;
        this.pageSize = 10;
        this.pagesCount = 0;
        this.paginate = false;
    }
}

export { CashFlow, CashFlowCampaign, CashFlowProject, CashFlowPerson, CashFlowFilters }