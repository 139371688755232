class Node {
    constructor() {
        this.id = 0;
        this.checked = false;
        this.indeterminate = false;
        this.canSelectAllChildrenNodes = true;
        this.canSelect = true;
        this.description = "";
        this.taxProjectWithoutIncentiveLawOrPerson = 0;
        this.taxProjectIncentivedOrAucOrCompany = 0;
        this.totalToTransfer = 0;
        this.totalToConciliate = 0;
        this.children = [];
        this.campaignLevel3 = [];
        this.providerName = "";
        this.countChildrenChecked = 0;
        this.father = { description: "" }
    }
}

class Tree extends Node {
    constructor() {
        this.countDonations = 0;
        this.countCampaigns = 0;
        this.countProjects = 0;
        this.countCarts = 0;
        this.filter = new TreeFilter();
    }
}

class TreeFilter {
    constructor() {
        this.entityId = 0;
        this.statusConciliation = 0;
        this.projectType = 0;
        this.pageNumber = 0;
        this.projectId = 0;
        this.paymentMethod = 0;
        this.companyId = 0;
        this.campaignId = 0;
        this.initialFinalDates = [];
        this.initialFinalDatesConciliation = [];
        this.listCompany = [];
        this.listCampaign = [];
        this.companies = [];
    }
}

class PersonNode {
    constructor() {
        this.personId = 0;
        this.userId = 0;
        this.cartId = 0;
        this.cartItemId = 0;
        this.personName = "";
        this.cpf = "";
        this.registrationId = 0;
        this.email = "";
        this.donationValue = 0;
        this.donationsId = 0;
        this.donationsProject = [];
    }
}

class DonationsProjectNode {
    constructor() {
        this.projectId = 0;
        this.projectName = "";
        this.incentived = false;
        this.valueDonation = 0;
    }
}

export { Node, Tree, TreeFilter, PersonNode, DonationsProjectNode }