const DonationConciliationStatus = {
     // Status quando a donation é criada através de um pagamento por débito em folha
     PayRollOnConciliationBase: 1,
     // Status quando foi feito o download da doação para ser conciliada pela empresa
     PayRollOnConciliationByCompany: 2,
     // Status quando a doação foi conciliada pela empresa (a empresa fez o upload da doação)
     PayRollConciliedByCompany: 3,
     // Doação quando a empresa transfere para a Auc
     PayRollCompanyTransferedToAuc: 4,
     // Conciliado pela AUC
     ConciliedByAuc: 5,
     // Pendente de transferencia para a instituição
     PendentAcceptToInstitution: 6
};

export default DonationConciliationStatus;