import BaseCrudService from "./baseCrud.service";

export default class MnisteryService extends BaseCrudService {
    constructor() {
        super("Ministery");
    }  

    informSportMinistery(selectedItems){
        return this.postRequest("InformSportMinistery", {selectedItems: selectedItems});
    }
}