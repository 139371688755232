const CashFlowStatusZeroPaymentStatus = {
	Approved: 1,
	Pending: 2,
	Rejected: 3
}

const CashFlowStatusZeroPaymentStatusList = [
	{ id: CashFlowStatusZeroPaymentStatus.Approved, name: "Aprovado" },
	{ id: CashFlowStatusZeroPaymentStatus.Pending, name: "Pendente" },
	{ id: CashFlowStatusZeroPaymentStatus.Rejected, name: "Rejeitado" }
  ]

export { CashFlowStatusZeroPaymentStatus, CashFlowStatusZeroPaymentStatusList };