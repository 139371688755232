export default class CashFlowStatusZeroFilters {
    constructor() {
        this.entityId = 0;
        this.companiesIds = [];
        this.campaignsIds = [];
        this.projectsIds = [];
        this.fromCheckoutDate = null;
        this.toCheckoutDate = null;
        this.paymentMethodsIds = [];
        this.paymentStatus = 1;
        this.conciliationStatus = [];
    }
}