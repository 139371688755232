<template>
  <div>
    <!-- #modal user details  -->
    <Modal
      :modalTitle="node.father.description"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="640px"
      :modalFooter="true"
      v-model="dialog"
    >
      <!-- body  -->
      <template>
        <v-container fluid>
          <v-layout align-start>
            <v-flex>
              <v-layout align-center>
                <v-flex shrink pr-3>
                  <div class="avatar-profile big">
                    <img :src="getUrl(node.children[0].personName)" alt />
                  </div>
                </v-flex>
                <v-flex>
                  <h3 class="fw-400 text-dark f-size-18">{{ node.children[0].personName }}</h3>
                  <div>
                    <faIcon type="fal" name="id-card-alt" size="14px" class="mr-3" />
                    {{ node.children[0].cpf | FormatDocument }}
                  </div>
                  <div>
                    <faIcon type="fal" name="envelope" size="14px" class="mr-3" />
                    {{ node.children[0].email }}
                  </div>
                  <div v-if="node.children[0].registrationId != null">
                    <faIcon type="fal" name="address-card" size="14px" class="mr-3" />
                    {{node.children[0].registrationId}}
                  </div>
                  <!-- <div>
                    <faIcon type="fal" name="phone" size="14px" class="mr-3" />55 11 98593.8402
                  </div> -->
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex text-xs-right>
              <div class="fw-700 text-gray f-size-20">#{{ node.children[0].cartId }}</div>
              <!-- <div
                class="mt-3 fw-700 text-dark f-size-16"
              >Total:{{ node.children[0].donationValue | FormatMoney }}</div> -->
            </v-flex>
          </v-layout>

          <hr class="md mt-4 mb-3" />

          <v-layout py-2>
            <v-flex caption>Projeto</v-flex>
            <v-flex text-xs-right caption>Valor</v-flex>
          </v-layout>

          <hr class="sm mt-0 mb-2" />

          <v-layout py-2 v-for="(project,i) in node.children[0].donationsProject" :key="i">
            <v-flex>{{ project.projectName}} <small>({{project.incentived ? "Lei de incentivo" : "Sem lei de incentivo"}})</small> </v-flex>
            <v-flex text-xs-right>{{ project.valueDonation | FormatMoney}}</v-flex>
          </v-layout>

          <hr class="sm mt-0 mb-2" />
          <v-layout py-2>
            <v-flex v-if="paymentMethod == paymentMethodEnum.Ticket">
              <strong>{{node.children[0].providerName}}</strong>
              <ul>
                <li v-for="code in node.children[0].code" :key="code">{{code}}</li>
              </ul>
              </v-flex>
            <v-flex text-xs-right class="fw-700 text-dark f-size-16">Total:{{ node.children[0].donationValue | FormatMoney }}</v-flex>
          </v-layout>
        </v-container>
      </template>

      <!-- action buttons  -->
      <template v-slot:footer>
        <v-container fluid>
          <v-layout>
            <v-flex>
              <v-btn flat round color="white" class="btn-default px-3" large @click="close">Voltar</v-btn>
            </v-flex>
            <v-flex text-xs-right>
              <v-btn flat color="white" class="btn-yellow px-3" v-if="paymentMethod == paymentMethodEnum.Ticket" @click="$emit('resendTicket',node); close()">
                <faIcon type="fas" name="sync-alt" size="16px" class="mr-2" />Reenviar Boleto
              </v-btn>
              <v-btn flat color="white" class="btn-red px-3" large v-if="paymentMethod == paymentMethodEnum.Ticket" @click="$emit('deleteTicket',node); close()">
                <faIcon type="fas" name="trash" size="16px" class="mr-2" />Excluir doação
              </v-btn>
              <v-btn flat color="white" class="btn-primary px-3" large v-if="paymentMethod == paymentMethodEnum.Ticket" @click="$emit('conciliateTicket',node); close()">
                <faIcon type="fas" name="box-check" size="16px" class="mr-2" />Conciliado
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </Modal>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { Node, PersonNode, DonationsProjectNode } from "@/scripts/models/tree.model";
export default {
  props: {
    node: new Node(), 
    paymentMethod: ""
  },
  components: {
    Modal
  },
  data() {
    return {
      dialog: false,
      paymentMethodEnum: ""
    };
  },
  created() {
    this.paymentMethodEnum = PaymentMethod;
    if(this.node.children.length == 0){
      var personNode = new PersonNode();
      personNode.donationsProject.push(new DonationsProjectNode());
      this.node.children.push(personNode);
    }      
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    getUrl(name) {
      let url =
        "https://ui-avatars.com/api/?font-size=0.40&background=F1F2F5&color=80849A&size=70&name=";
      if (name != null) url += name.split().join("+");
      return url;
    }
  }
};
</script>
