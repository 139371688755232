<template>
  <div>
    <!-- #modal filtros  -->
    <Modal
      modalTitle="Filtros"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="400px"
      :modalFooter="true"
      v-model="dialog"
      @input="input"
    >
      <!-- body  -->
      <template>
        <v-container fluid grid-list-sm pa-3>
          <v-layout align-center wrap>
            <v-flex xs12>
              <InputMultSelect
                v-model="cashFlowFilters.companiesIdsSelected"
                textLabel="Empresa"
                valueAttribute="id"
                textAttribute="entity.name"
                :required="false"
                :showAllOptions="true"
                :items="cashFlowFiltersPropListCompanies"
              />
            </v-flex>

            <!-- <v-flex xs12>
              <inputSelect
                v-model="cashFlowFilters.campaignId"
                textLabel="Campanha"
                valueAttribute="id"
                textAttribute="description"
                unselectedValue="null"
                unselectedText="Todas"
                :items="cashFlowFiltersProp.listCampaign"
              />
            </v-flex>-->
            <v-flex xs12>
              <InputMultSelect
                v-model="cashFlowFilters.projectIdsSelected"
                textLabel="Projetos"
                valueAttribute="id"
                textAttribute="fullName"
                unselectedValue="null"
                unselectedText="Todos"
                :items="cashFlowFiltersPropListProjects"
              />
            </v-flex>
            <!-- <v-flex xs12 v-if="showPaymentMethod">
              <inputSelect
                v-model="cashFlowFilters.paymentMethod"
                textLabel="Meios de Pagamento"
                valueAttribute="id"
                textAttribute="name"
                unselectedValue="null"
                unselectedText="Todas"
                :items="items"
              />
            </v-flex>-->
            <v-flex xs12 v-if="pageNumber == pageNumberEnum.ConciliedAUC">
              <InputMultSelect
                v-model="cashFlowFilters.typeProjectsIdSelected"
                textLabel="Tipo de Projeto"
                valueAttribute="id"
                textAttribute="name"
                unselectedValue="null"
                unselectedText="Todos"
                :items="types"
              />
            </v-flex>
            <v-flex xs12 mt-2>
              <InputDateRange
                v-model="cashFlowFilters.initialFinalDates"
                textLabel="Período das doações"
                place-holder
                :monthsToShow="2"
                invalidInputMessage="Preencha a vigência da campanha"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </template>

      <!-- action buttons  -->
      <template v-slot:footer>
        <v-container fluid>
          <v-layout>
            <v-flex>
              <v-btn
                flat
                round
                color="white"
                class="btn-default px-4"
                large
                @click="close"
                >Voltar</v-btn
              >
            </v-flex>
            <v-flex>
              <v-btn
                flat
                round
                color="white"
                class="btn-default px-4"
                large
                @click="clearFilters"
                >Limpar Filtros</v-btn
              >
            </v-flex>
            <v-flex text-xs-right>
              <v-btn
                flat
                color="white"
                class="btn-primary px-4"
                large
                @click="filter"
                >Aplicar</v-btn
              >
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </Modal>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import { TreeFilter } from "@/scripts/models/tree.model";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import {
  ProjectTypes,
  GetProjectTypeName,
} from "@/scripts/models/enums/projectTypes.enum";
import { mapGetters, mapState, mapActions } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";
import { CashFlowPages } from "@/scripts/models/enums/cashFlowPages.enum";
export default {
  props: ["value", "cashFlowFiltersProp", "pageNumber", "paymentMethod"],
  components: {
    Modal,
  },
  data() {
    return {
      dialog: false,
      cashFlowFilters: new TreeFilter(),
      pageNumberEnum: "",
      items: [
        { id: PaymentMethod.PayrollDeduction, name: "Débito em Folha" },
        { id: PaymentMethod.Ticket, name: "Boleto" },
        { id: PaymentMethod.CreditCard, name: "Cartão de Crédito" },
      ],
      types: [
        {
          id: ProjectTypes.ProjetoIncentivado,
          name: GetProjectTypeName(ProjectTypes.ProjetoIncentivado),
        },
        {
          id: ProjectTypes.ProjetoSemIncentivoFiscal,
          name: GetProjectTypeName(ProjectTypes.ProjetoSemIncentivoFiscal),
        },
        {
          id: ProjectTypes.Ticket,
          name: GetProjectTypeName(ProjectTypes.Ticket),
        },
      ],
      cashFlowFiltersPropListCompanies: [],
      cashFlowFiltersPropListProjects: [],
    };
  },
  created() {
    this.pageNumberEnum = CashFlowPages;
  },
  watch: {
    value() {
      this.dialog = this.value;
    },
    "cashFlowFilters.initialFinalDates": {
      handler: function (after, before) {
        if (after) {
          this.cashFlowFilters.initialDate = after[0];
          this.cashFlowFilters.finalDate = after[1];
        } else {
          this.cashFlowFilters.initialFinalDates = [null, null];
        }
      },
      deep: true,
    },
    "cashFlowFilters.initialFinalDatesConciliation": {
      handler: function (after, before) {
        if (after) {
          this.cashFlowFilters.initialDateConciliation = after[0];
          this.cashFlowFilters.finalDateConciliation = after[1];
        } else {
          this.cashFlowFilters.initialFinalDatesConciliation = [null, null];
        }
      },
      deep: true,
    },
    cashFlowFiltersProp: {
      handler: function (after, before) {
        if (
          !this.cashFlowFiltersProp ||
          !this.cashFlowFiltersProp.listCompanies
        )
          this.cashFlowFiltersPropListCompanies = [];
        this.cashFlowFiltersPropListCompanies =
          this.cashFlowFiltersProp.listCompanies
            .slice()
            .sort((a, b) =>
              a.entity.name.trim().localeCompare(b.entity.name.trim())
            );
        if (!this.cashFlowFiltersProp || !this.cashFlowFiltersProp.listProjects)
          this.cashFlowFiltersPropListProjects = [];
        this.cashFlowFiltersPropListProjects =
          this.cashFlowFiltersProp.listProjects
            .slice()
            .sort((a, b) => a.fullName.trim().localeCompare(b.fullName.trim()));
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["hasPermission", "logged"]),
    showPaymentMethod() {
      if (this.pageNumber == CashFlowPages.ConciliedAUC) {
        var hasPermissionModal = this.hasPermission(
          [
            Permissions.CRUDInstitution,
            Permissions.CRUDCompany,
            Permissions.CRUDUserAll,
          ],
          null,
          true
        );
        return hasPermissionModal;
      }
      return false;
    },
    /*cashFlowFiltersPropListCompanies() {
      if(!this.cashFlowFiltersProp || !this.cashFlowFiltersProp.listCompanies)
        return [];
      return this.cashFlowFiltersProp.listCompanies.sort((a,b) => a.entity.name.trim().localeCompare(b.entity.name.trim()));
    },*/
    /*cashFlowFiltersPropListProjects() {
      if(!this.cashFlowFiltersProp || !this.cashFlowFiltersProp.listProjects)
        return [];
      return this.cashFlowFiltersProp.listProjects.sort((a,b) => a.fullName.trim().localeCompare(b.fullName.trim()));
    }*/
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.$emit("close");
      this.dialog = false;
    },
    filter() {
      this.cashFlowFilters.paymentMethod = this.paymentMethod;
      this.$emit("filter", this.cashFlowFilters);
    },
    clearFilters() {
      this.cashFlowFilters.companiesIdsSelected = [];
      this.cashFlowFilters.projectIdsSelected = [];
      this.cashFlowFilters.typeProjectsIdSelected = [];
      this.cashFlowFilters.initialFinalDates = [null, null];
      this.filter();
    },
    input() {
      if (!this.dialog) this.$emit("close");
    },
  },
};
</script>
