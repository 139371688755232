<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" scrollable max-width="600">
        <v-card>
          <v-card-text>
            <v-flex flex-row w-100>
              <v-flex pa-5 w-100>
                <v-layout row wrap>
                  <v-flex xs12 mb-3>
                    <InputDate
                      textLabel="Data de transferência"
                      place-holder="__/__/____"
                      v-model="cashFlowTransfer.transferDate"
                      :max="$moment().format('YYYY-MM-DD')"
                    ></InputDate>
                  </v-flex>
                  <v-flex xs8>
                    <InputText
                      ref="fileNameInput"
                      type="text"
                      place-holder="Upload do comprovante"
                      invalidInputMessage="Escolha um arquivo"
                      v-model="cashFlowTransfer.file.name"
                    />
                  </v-flex>
                  <v-flex xs4 father-center>
                    <label for="upload-comprovant" class="btn-search-rh ma-0 ml-3">Procurar</label>
                    <input
                      type="file"
                      id="upload-comprovant"
                      ref="uploadFile"
                      class="d-none"
                      @change="fileChange"
                    />
                  </v-flex>
                  <v-flex xs12 mt-3>
                    <InputTextArea
                      ref="observationInput"
                      rows="3"
                      place-holder="Observação"
                      v-model="cashFlowTransfer.observation"
                      textLabel="Observação"
                      invalidInputMessage="Preencha uma observação"
                      labelAreaCharLimit="250"
                    ></InputTextArea>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex pb-5 text-xs-right>
                <button
                  class="mr-5 fw-700 text-gray"
                  @click="dialog = false; $emit('cancel')"
                >CANCELAR</button>
                <button
                  :disabled="disabledUpload"
                  class="send-archive-btn mr-5 fw-700 text-primary"
                  @click="transfer();"
                >CONFIRMAR TRANSFERÊNCIA</button>
              </v-flex>
            </v-flex>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>
<script type="plain/text">
import { CashFlowTransferType } from "@/scripts/models/enums/cashFlowTransferType.enum";
import CashFlowTransfer from "@/scripts/models/cashFlowTransfer.model";
import { CashFlowPages } from "@/scripts/models/enums/cashFlowPages.enum";
export default {
  props: ["value", "pageNumber"],
  components: {},
  data() {
    return {
      dialog: false,
      transferDate: null,
      observation: "",
      cashFlowTransfer: new CashFlowTransfer(),
      disabledUpload: true
    };
  },
  created() {},
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  methods: {
    open() {
      this.fileName = "";
      this.cashFlowTransfer = new CashFlowTransfer();
      this.dialog = true;
      this.selectTypeTransfer();
    },
    selectTypeTransfer() {
      if (this.pageNumber == CashFlowPages.TransferToAUC)
        this.cashFlowTransfer.type = CashFlowTransferType.CompanyTransferToAuc;
      else if (this.pageNumber == CashFlowPages.PendentTransferToInstitution)
        this.cashFlowTransfer.type =
          CashFlowTransferType.AucTransferToInstitution;
    },
    fileChange(event) {
      this.cashFlowTransfer.file.name = event.target.files[0].name;
      let file = event.target.files[0];
      if (this.cashFlowTransfer.file.name != "") this.disabledUpload = false;
      if (file) {
        let reader = new FileReader();
        this.cashFlowTransfer.file.name = file.name;
        reader.onload = function() {
          this.cashFlowTransfer.file.path = reader.result;
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
    transfer() {
      this.$emit("transfer", this.cashFlowTransfer);
      this.dialog = false;
    }
  }
};
</script>
